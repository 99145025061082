@import '../../_variables.scss';

.confirmationPopup {
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  overflow: hidden;
  z-index: 9999;
  &.hidden {
    display: none;
  }
  .overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    .popupWindow {
      display: flex;
      flex-direction: column;
      background-color: #ffffff;
      width: calc(100% - 32px);
      position: relative;
      max-width: 480px;
      border: 1px solid $highlightColor;
      max-height: 100%;
      border-radius: 4px;
      transition: all 300ms ease 0s;
      padding: 20px 16px;
      position: relative;
      .confirmTitle {
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.15px;
        color: $textTitleColor;
      }
      .confirmBody {
        margin-top: 4px;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: $textColor;
        p {
          margin: 0px;
          &+p {
            margin-top: 12px;
          }
        }
      }
      .popupControls {
        margin-top: 32px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        button+button {
          margin-left: 16px;
        }
      }
    }
  }
}
