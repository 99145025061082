@import '../_variables.scss';

.clientMagagerTools {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px 0px 0px;
  .searchTitle {
    padding: 16px 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    &>label {
      font-size: 22px;
      line-height: 28px;
      color: $textColor;
      font-weight: 600;
    }
  }
  .searchInputs {
    width: 100%;
    max-width: 800px;
    padding: 0px 20px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    &+.searchInputs {
      margin-top: 16px;
    }
    .inputBlock {
      width: 100%;
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      label {
        font-size: 14px;
        font-weight: 600;
        color: #666666;
        margin-bottom: 4px;
      }
    }
    button {
      width: 100px;
      height: 46px;
      flex-shrink: 0;
    }
  }
  .tasksContainer {
    margin-top: 10px;
    padding: 16px 20px 16px;
    max-height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    border-top: 1px solid $highlightColor;
    .taskItem {
      padding: 10px 0px 5px;
      margin-right: 12px;
      .taskTitle, .taskLink {
        position: relative;
        display: block;
        font-size: 14px;
        font-weight: 600;
        color: #333333;
        text-transform: uppercase;
        margin-bottom: 8px;
        &::before {
          content: '';
          position: absolute;
          left: 0px;
          width: 100%;
          bottom: -10px;
          height: 10px;
          border-radius: 2px;
          border-top: 1px solid #333333;
          border-left: 1px solid #333333;
          border-right: 1px solid #333333;
        }
        &.noBorder {
          &::before {
            border-color: transparent;
          }
        }
      }
      .taskLink {
        text-decoration: none;
        border-color: $primaryColor;
        color: $primaryColor;
        cursor: pointer;
        &::before {
          border-color: $primaryColor;
        }
      }
      .photosContainer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .imageItem {
          display: flex;
          flex-shrink: 0;
          width: 160px;
          height: 160px;
          margin: 0px 12px 10px 0px;
          background-size: contain;
          border: 1px solid #dddddd;
          border-radius: 4px;
          background-position: center;
          background-size: cover;
          cursor: pointer;
          &:last-child {
            margin-right: 0px;
          }
        }
      }
    }
  }
  .bottomContainer {
    border-top: 1px solid $highlightColor;
    margin-top: auto;
    padding: 0px 20px;
  }
}

@media all and (max-width: 768px) {
  .clientMagagerTools {
    .searchInputs {
      flex-direction: column;
      .inputBlock {
        margin-right: 0px;
        margin-bottom: 20px;
      }
    }
    .tasksContainer {
      max-height: initial;
      overflow: visible;
      .taskItem {
        min-width: 135px;
        .photosContainer {
          .imageItem {
            width: 125px;
            height: 125px;
          }
        }
      }
    }
  }
}
