@import '../../_variables.scss';

.sidebarView {
  position: fixed;
  top: 64px;
  left: 0px;
  width: 100%;
  bottom: 0px;
  z-index: 80;
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0)
  );
  &:not(.open) {
    transform: translate(-100%, 0%);
  }
  .sidebarContainer {
    width: 260px;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #cccccc;
    background-color: $mainBackground;
  }
  .menuList {
    width: 100%;
    height: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 8px 0px 8px;
  }
  .bottomControls {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 16px;
    border-top: 1px solid #cccccc;
    button {
      width: 100%;
    }
  }
}

@media all and (max-width: 768px) {
  .sidebarView {
  }
}
