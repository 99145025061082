.graphListView {
  .graphListItem {
    border-bottom: 1px solid #eee;
    padding: 3px 0px;
     margin: 3px 0px;
    a {
      cursor: pointer;
    }
    span {
      min-width: 32px;
      float: right;
      text-align: right;
    }
    .valueLabel:first-child {
      span {
        float: left;
        text-align: left;
      }
    }
    .valueLabel:last-child {
      span:not(:last-child) {
        margin-left: 12px;
      }
    }
    .valueLabel {
      vertical-align: top;
      width: 50%;
      display: inline-block;
    }
  }
}
