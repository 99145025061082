@import '../../variables.scss';

.imageList {
  display: flex;
  flex-direction: column;
  .imagesContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .imageItem {
      position: relative;
      display: flex;
      flex-shrink: 0;
      width: 160px;
      height: 160px;
      margin: 0px 10px 10px 0px;
      background-size: contain;
      border: 1px solid #dddddd;
      border-radius: 4px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      cursor: pointer;
      .removePhoto {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 36px;
        height: 36px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255,255,255,0.3);
      }
    }
  }
  input[type='file'] {
    display: none;
  }
}
