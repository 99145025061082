@import '../../_variables.scss';

.textInput {
  position: relative;
  input,
  textarea {
    border: solid 1px $highlightColor;
    background-color: #ffffff;
    border-radius: 4px;
    padding: 13px 12px;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: $textTitleColor;
    &::placeholder {
      color: $textSubtleColor;
    }
    &[type=number]::-webkit-inner-spin-button, 
    &[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
      margin: 0; 
    }
    &[readonly], &[disabled] {
      color: $textColor;
      background-color: #f6f6f6;
      cursor: default;
    }
  }
  textarea {
    resize: none;
    min-height: 120px;
    font-family: inherit;
  }
  &.password {
    input {
      padding-right: 40px;
    }
  }
  .passwordToggle {
    position: absolute;
    right: 10px;
    top: 11px;
    width: 26px;
    height: 26px;
    background-image: url(/images/icons/visible.svg);
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    opacity: 0.6;
    &.visible {
      background-image: url(/images/icons/invisible.svg);
    }
  }
  &.simplified {
    input,
    input[readonly],
    textarea,
    textarea[readonly] {
      border: none;
      border-radius: 0px;
      font-size: 16px;
      background-color: transparent !important;
      cursor: default !important;
    }
  }
  &.error {
    input,
    textarea {
      border-color: $errorColor;
    }
  }
}
