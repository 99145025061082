@import '../../_variables.scss';

.snackbar {
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  //height: 250px;
  height: 60px;
  z-index: 1301;
  text-align: center;
  transition: bottom 0.25s ease 0s;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  &:not(.show) {
    bottom: -300px;
  }
  /*&.success {
    .snackbarContainer {
      background-color: $successColor;
    }
  }
  &.error {
    .snackbarContainer {
      background-color: $errorColor;
    }
  }*/
  .snackbarOverlay {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    opacity: 0.3;
    /*background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      #000000
    );*/
  }
  .snackbarContainer {
    position: relative;
    background-color: #333333;
    display: inline-flex;
    align-items: center;    
    padding: 8px 12px;
    border-radius: 4px;
    .snackbarText {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: #ffffff;
    }
  }
}

@media all and (min-width: 768px) {
  .snackbar {
    .snackbarContainer {
      max-width: 480px;
    }
  }
}
