@import '../../_variables.scss';

.objectEditInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  &.hidden {
    display: none;
  }
  label {
    width: 20%;
    max-width: 170px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    font-size: 12px;
    font-weight: 700;
    color: #666666;
    text-transform: uppercase;
    text-align: right;
    padding-right: 15px;
    .tip {
      position: relative;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      flex-shrink: 0;
      background-color: $highlightColor;
      margin-right: 6px;
    }
  }
  label+.inputElem {
    width: 80%;
  }
  .inputElem {
    flex: 1 0;
    width: 100%;
    .inputError {
      position: absolute;
      color: $errorColor;
      font-size: 12px;
      margin: -5px 0px 0px;
    }
  }
}

@media all and (max-width: 768px) {
	.objectEditInput {
    padding: 10px 0px 0px;
    flex-direction: column;
    label {
      padding-right: 0px;
      padding-bottom: 4px;
      width: 100%;
      max-width: initial;
      text-align: left;
      justify-content: flex-start;
    }
    label+.inputElem {
      width: 100%;
    }
  }
}
