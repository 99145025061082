@import '../_variables.scss';

.objectListView {
  padding: 16px 20px;
  .listTitle {
    margin: 24px 0px 16px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    &>label {
      font-size: 22px;
      line-height: 28px;
      color: $textColor;
      font-weight: 600;
    }
    .auditButton {
      padding: 5px;
      margin-left: auto;
      cursor: pointer;
      position: relative;
      &+.filterButton {
        margin-left: 10px;
      }
    }
    .filterButton {
      padding: 5px;
      margin-left: auto;
      cursor: pointer;
      position: relative;
    }
  }
  .searchSidebar {
    position: fixed;
    top: 64px;
    right: 0px;
    width: 100%;
    bottom: 0px;
    z-index: 80;
    transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0)
    );
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    &:not(.open) {
      transform: translate(100%, 0%);
    }
    .searchView {
      width: 320px;
    }
  }
  &.embedded {
    padding: 0px;
    .tableContainer {
      padding: 0px;
    }
    .tableCard {
      .listControls {
        margin: 0px;
      }
    }
  }
  .tableCard {
    padding: 0px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    .listControls {
      flex-shrink: 0;
      width: auto;
      margin: 16px 0px;
      tbody {
        tr {
          td {
            text-align: right;
            &:last-child {
              text-align: center; 
            }
          }
        }
      }
    }
  }
  .tableContainer {
    width: 100%;
    overflow: auto;
    padding: 16px 0px;
  }
  .objectListTable {
    table-layout: fixed;
    width: 100%;
    margin-bottom: 10px;
    border: none;
    border-collapse: collapse;
    font-size: 15px;
    thead {
			tr {
				th {
          height: 60px;
          padding: 0px 10px;
          text-align: left;
          &.sortable {
						cursor: pointer;
						&:hover {
							background-color: rgba(0,0,0,0.08);
						}
					}
          .sortIcon {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            vertical-align: middle;
            margin-left: 12px;
            height: 16px;
          }
          .headerCellContent {
            border: none;
            color: #999999;
            font-weight: 400;
            text-align: left;
            padding: 10px;
            height: 60px;
            display: flex;
            flex-direction: row;
            align-items: center;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 10px;
          overflow: hidden;
          white-space: nowrap;
          border-top: 1px solid $highlightColor;
          height: 41px;
          &.control {
            padding: 0px 5px;
            overflow: visible;
            .page-link {
              padding: 5px;
            }
          }
        }
      }
    }
  }
  .bottomControls {
    min-height: 90px;
  }
  .importButton {
    position: fixed;
    z-index: 5;
    right: 115px;
    bottom: 35px;
  }
  .createButton {
    position: fixed;
    z-index: 5;
    right: 35px;
    bottom: 35px;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    color: #ffffff;
    background-color: $primaryColor;
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media all and (max-width: 768px) {
  .objectListView {
    .card {
      margin-bottom: 30px;
      table {
        margin-top: -10px;
        margin-bottom: 0px;
        tbody {
          tr {
            &:first-child {
              td {
                border-top: none;
              }
            }
            td {
              white-space: normal;
            }
          }
        }
      }
    }
  }
}
