@import '../../_variables.scss';

.massEditTool {
  display: flex;
  flex-direction: column;
  height: 100%;
  .searchTitle {
    padding: 16px 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    &>label {
      font-size: 22px;
      line-height: 28px;
      color: $textColor;
      font-weight: 600;
    }
  }
  .selectsContainer {
    width: 100%;
    max-width: 590px;
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    .textInput,
    .selectInput,
    .dateInput,
    .fileInput {
      width: 100%;
      padding: 0px;
      margin: 0px 0px 12px;
    }
  }
  .buttonsContainer {
    width: 100%;
    padding: 16px 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    border-top: 1px solid #e1e1e1;
    .buttonPlaceholder {
    }
    button:not(:last-child) {
      margin-right: 20px;
      margin-bottom: 16px;
    }
  }
}

@media all and (max-width: 768px) {
  .massEditTool {
    .buttonsContainer {
      flex-direction: column;
      button {
        width: 100%;
        max-width: 300px;
      }
    }
  }
}
