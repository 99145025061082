@import '../_variables.scss';

.dashboardView {
  padding: 16px 20px;
  .listTitle {
    font-size: 22px;
    line-height: 28px;
    color: $textColor;
    font-weight: 600;
    margin: 16px 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    .filterButton {
      padding: 5px;
      margin-left: auto;
      cursor: pointer;
    }
  }
  .searchSidebar {
    position: fixed;
    top: 64px;
    right: 0px;
    width: 100%;
    bottom: 0px;
    z-index: 80;
    transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0)
    );
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    &:not(.open) {
      transform: translate(100%, 0%);
    }
    .searchView {
      width: 320px;
    }
  }
  .graph {
    padding: 32px 16px 20px;
    border-radius: 4px;
    box-shadow:
      0px 1px 3px 0px rgba(0,0,0,0.2),
      0px 1px 1px 0px rgba(0,0,0,0.14),
      0px 2px 1px -1px rgba(0,0,0,0.12);
    background-color: #ffffff;
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}
