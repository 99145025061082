@import '../_variables.scss';

.headerView {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  background-color: #ffffff;
  height: 64px;
  border-bottom: 1px solid $highlightColor;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 16px;
  z-index: 2;
  .backLink,
  .menuButton,
  .searchButton {
    padding: 5px;
    margin: 0px 5px;
		cursor: pointer;
		display: flex;
    align-items: center;
    color: $textTitleColor;
		.material-icons {
			font-size: 28px;
    } 
  }
  .headerLogo {
    width: 44px;
    height: 44px;
    background-image: url(/logo.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 20px;
  }
  .pageInfo {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    .pageTitle {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: $textTitleColor;
    }
    .outletButton {
      position: relative;
      display: flex;
      &::after {
        content: '';
        width: 20px;
        height: 20px;
        margin-left: 6px;
        background-image: url(/images/icons/expand.svg);
        background-position: center;
        background-repeat: no-repeat;
      }
      .outletTitle {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
      }
    }
  }
  .userButton {
    width: 32px;
    height: 32px;
    margin: 0px 4px;
    border: 1px solid $textSubtleColor;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    span {
      font-size: 15px;
      color: $textColor;
      font-weight: 500;
      text-transform: uppercase;
    }
  }
}
