@import '../_variables.scss';

.settingsView {
	display: flex;
	flex-direction: column;
  padding: 20px 24px;
	.settingsTitle {
		max-width: 680px;
		font-weight: 500;
		font-size: 24px;
		line-height: 32px;
		letter-spacing: 0.18px;
		color: $textTitleColor;
	}
	.settingsBlock {
		max-width: 680px;
		display: flex;
		flex-direction: column;
		background: #FFFFFF;
		border: 1px solid $highlightColor;
		box-sizing: border-box;
		border-radius: 4px;
		padding: 20px 15px;
		margin: 25px 0px 15px;
		.blockTitle {
			font-weight: 500;
			font-size: 18px;
			line-height: 26px;
			letter-spacing: 0.15px;
			color: $textTitleColor;
			border-bottom: 1px solid $highlightColor;
			padding-bottom: 8px;
		}
		.pairBlock {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			.inputBlock {
				width: calc(50% - 12px);
			}
		}
		.inputBlock {
			display: flex;
			flex-direction: column;
			label {
				margin: 24px 0px 4px;
				font-size: 14px;
				line-height: 20px;
				letter-spacing: 0.25px;
			}
		}
		button {
			margin-top: 24px;
			margin-left: auto;
		}
	}
}

@media all and (max-width: 768px) {
	.settingsView {
		.settingsBlock {
			.pairBlock {
				flex-direction: column;
				.inputBlock {
					width: 100%;
				}
			}
			button {
				width: 100%;
				margin-top: 40px;
			}
		}
	}
}
