@import '../../_variables.scss';

.detailedSelectInput {
  .detailsTable {
    table-layout: fixed;
    width: 100%;
    margin-bottom: 15px;
    border: none;
    border-collapse: collapse;
    font-size: 15px;
    thead {
      tr {
        th {
          height: 40px;
          border: none;
          color: #999999;
          font-weight: 400;
          text-align: left;
          padding: 5px;
          height: 41px;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 5px;
          overflow: hidden;
          white-space: nowrap;
          border-top: 1px solid $highlightColor;
          height: 41px;
          overflow: visible;
        }
      }
    }
  }
}
