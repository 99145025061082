@import '../../variables.scss';

.additionalButtons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px 0px 0px;
  button+button {
    margin-left: 15px;
  }
}
