@import '../../_variables.scss';

.dateInput {
  position: relative;
  &.simplified {
    input,
    input[readonly] {
      border: none;
      border-radius: 0px;
      font-size: 16px;
      background-color: transparent !important;
      cursor: default !important;
    }
  }
  &.expandTop {
    .rdt {
      .rdtPicker {
        bottom: 100%;
      }
    }
  }
  &.clearable {
    input {
      padding-right: 50px;
    }
    .clearButton {
      position: absolute;
      top: 50%;
      right: 0px;
      transform: translate(0px, -50%);
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
  input {
    border: solid 1px $highlightColor;
    background-color: #ffffff;
    border-radius: 4px;
    padding: 13px 12px;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: $textTitleColor;
    &[readonly], &[disabled] {
      color: $textColor;
      background-color: #f6f6f6;
    }
    &::placeholder {
      color: $textSubtleColor;
    }
  }
}
