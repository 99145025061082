@import '../_variables.scss';

.objectListElem {
  .link-list {
    padding: 5px 0px;
  }
  .page-link {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    span {
      &.clickable {
        cursor: pointer;
        color: $primaryColor;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
