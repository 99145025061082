$primaryColor: #2196F3;
$secondaryColor: #53b0e7;

$mainBackground: #FBFCFD;

$raitingSuperbColor: #037F5F;
$raitingGoodColor: #B5D45C;
$raitingPoorColor: #FFCC31;

$successColor: #B5D45C;
$errorColor: #D72C0D;
$disabledColor: #C4C4C4;

$textColor: rgba(0, 0, 0, 0.6);
$textTitleColor: rgba(0, 0, 0, 0.87);
$textSubtleColor: rgba(0, 0, 0, 0.38);
$highlightColor: rgba(0, 0, 0, 0.12);
