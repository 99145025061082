@import './_variables.scss';

body {
	font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
	font-size: 16px;
	font-weight: 400;
	overflow-x: hidden;
	-webkit-font-smoothing: antialiased;
	overscroll-behavior: none;
	background-color: $mainBackground;
	color: $textColor;
}

.customScrollBar {
	-webkit-overflow-scrolling: touch;
	&::-webkit-scrollbar {
		width: 8px;
	}
	&::-webkit-scrollbar-track {
		background-color: #eee;
		border-left: 1px solid #ddd;
		border-right: 1px solid #ddd;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #ddd;
		border-radius: 2px;
	}
	&::-webkit-scrollbar-thumb:hover {
		background-color: #ccc;
	}
}

*:hover {
	&>.tooltip {
		display: flex;
	}  
}

.tooltip {
	//display: flex;
	display: none;
	position: absolute;
	left: 0px;
	bottom: 28px;
	transform: translate(0%, 0%);
	width: 210px;
	background: rgba(0, 0, 0, 0.8);
	border-radius: 4px;
	padding: 8px 12px;
	font-size: 14px;
	font-weight: 400;
	text-transform: none;
	line-height: 20px;
	align-items: center;
	letter-spacing: 0.25px;
	color: #FFFFFF;
	&.nowrap {
		width: auto;
		white-space: nowrap;
	}
	&.center {
		left: 50%;
		transform: translate(-50%, 0%);
	}
	&.left {
		top: 0px;
		bottom: initial;
		left: initial;
		right: 100%;
		transform: translate(0%, 0%);
		&.top {
			top: initial;
			bottom: 100%;
			right: 0px;
		}
	}
	&.bottom {
		bottom: initial;
		top: 28px;
	}
}

.inputError {
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.25px;
	color: $errorColor;
	margin-top: 8px;
}

button {
  border-radius: 4px;
  background-color: $primaryColor;
  text-align: center;
  border: 1px solid $primaryColor;
	box-shadow: none;
  padding: 9px 16px;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	letter-spacing: 0.25px;
	color: #FFFFFF;
  cursor: pointer;
	&.outline {
		font-weight: 500;
		background-color: transparent;
		border: 1px solid $highlightColor;
		color: #000000;
		&.acive {
			color: $primaryColor;
			border-color: $primaryColor;
		}
	}
	&.link {
		background-color: transparent;
		border-color: transparent;
		color: $primaryColor;
	}
	&.noBorder {
		border-color: transparent;
	}
	&.danger {
		background-color: $errorColor;
		border-color: $errorColor;
	}
  &[disabled] {
		cursor: not-allowed;
    opacity: 0.5;
  }
}

.card {
	padding: 24px 16px 20px;
	border-radius: 4px;
	box-shadow:
		0px 1px 3px 0px rgba(0,0,0,0.2),
		0px 1px 1px 0px rgba(0,0,0,0.14),
		0px 2px 1px -1px rgba(0,0,0,0.12);
	background-color: #ffffff;
}

.actionButton {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	cursor: pointer;
	transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	background-color: transparent;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	position: relative;
	&:hover {
		background-color: rgba(0, 0, 0, 0.08);
	}
	&:disabled,
	&:disabled:hover {
		background-color: transparent;
		cursor: not-allowed;
		pointer-events: none;
		.material-icons {
			color: rgba(0, 0, 0, 0.2);
		}
	}
	svg {
		font-size: 22px;
	}
	.material-icons {
		color: rgba(0, 0, 0, 0.54);
	}
}

.adminContent {
	position: absolute;
	overflow-y: auto;
	vertical-align: top;
	display: inline-block;
	-webkit-overflow-scrolling: touch;
	width: 100%;
	height: 100vh;
	padding: 0px;
	z-index: 1;
	&.showUI {
		width: 100%;
		top: 64px;
		height: calc(100vh - 64px);
	}
	@media all and (max-width: 768px) {
		width: 100%;
		&.showUI {
			padding-left: 0px;
		}
	}
}
