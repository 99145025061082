@import '../../variables.scss';

.fileUploadInput {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 4px;
  input[type='file'] {
    display: none;
  }
  .uploadButton {
    margin-top: 5px;
    padding: 2px 8px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #000000;
    background: rgba(0, 0, 0, 0.05);
    border: 1px solid $highlightColor;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
  }
  .fileItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 0px;
    span {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: #000000;
      &.link {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .uploadIndicator {
      width: 20px;
      height: 20px;
      margin-left: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      &::after {
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        margin: 0px;
        border-radius: 50%;
        border: 2px solid $textSubtleColor;
        border-color: $textSubtleColor transparent $textSubtleColor transparent;
        animation: rotate 1.2s linear infinite;
      }
      @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(-360deg);
        }
      }
    }
    .removeButton {
      width: 20px;
      height: 20px;
      margin-left: 20px;
      background-image: url(/images/icons/remove_file.svg);
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }
}
