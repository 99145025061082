@import '../../_variables.scss';

.massEditView {
  .objectEditInput  {
    padding: 8px 0px;
    label {
      width: 30%;
    }
  }
  .buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 15px 0px 0px;
  }
}

@media all and (max-width: 768px) {
  .massEditView {
    .objectEditInput {
      label {
        width: 100%;
      }
    }
  }
}