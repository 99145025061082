@import '../../_variables.scss';

.auditLogsPopup {
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  overflow: hidden;
  z-index: 9999;
  &.hidden {
    display: none;
  }
  .overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    .popupWindow {
      display: flex;
      flex-direction: column;
      background-color: #ffffff;
      width: calc(100% - 32px);
      position: relative;
      max-width: 1400px;
      border: 1px solid $highlightColor;
      max-height: 100%;
      border-radius: 4px;
      transition: all 300ms ease 0s;
      padding: 20px 16px;
      position: relative;
      .auditTitle {
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.15px;
        color: $textTitleColor;
      }
      .auditSubtitle {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: $textSubtleColor;
      }
      .auditLogs {
        padding: 20px 0px 0px;
        overflow: auto;
        table {
          table-layout: fixed;
          width: 100%;
          border: none;
          border-collapse: collapse;
          thead {
            tr {
              th {
                height: 40px;
                padding: 0px 6px;
                text-align: left;
              }
            }
          }
          tbody {
            tr {
              td {
                padding: 6px;
                overflow: hidden;
                white-space: nowrap;
                border-top: 1px solid $highlightColor;
                height: 41px;
              }
            }
          }
        }
        .changes {
          width: 100%;
          display: flex;
          flex-direction: column;
          .change {
            flex-shrink: 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 2px 4px;
            &+.change {
              border-top: 1px solid rgba(0, 0, 0, 0.12);
            }
            &:hover {
              background-color: rgba(0, 0, 0, 0.04);
            }
            .column {
              width: 165px;
              flex-shrink: 0;
              overflow: hidden;
              word-wrap: break-word;
              white-space: normal;
            }
            .from,
            .to {
              width: 100%;
              overflow: hidden;
              word-wrap: break-word;
              white-space: normal;
            }
            .from {
              text-align: right;
              i {
                justify-content: flex-end;
              }
            }
            .to {
              text-align: left;
            }
            span {
              flex-shrink: 0;
              margin: 0px 4px;
            }
            i {
              display: flex;
              align-items: center;
              font-style: normal;
              padding: 0px 2px;
              &.null {
                color: #c6c6c6;
                font-style: italic;
              }
              &.boolean {
                font-weight: 500;
                color: #9078b6;
                span {
                  margin: 0px 4px 0px 0px;
                  font-size: 24px;
                  display: inline-block;
                  color: inherit;
                }
              }
              &.number {
                color: #4c9dcb;
                font-weight: 500;
              }
              &.string {
                color: #e67c05;
              }
            }
          }
        }
      }
      .popupControls {
        margin-top: 32px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        button+button {
          margin-left: 16px;
        }
      }
    }
  }
}
