@import '../_variables.scss';

.objectEditView {
	position: relative;
	padding: 16px 20px;
	.pageTitle {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		font-size: 24px;
		margin-bottom: 12px;
		color: $textTitleColor;
		.backLink {
			padding: 5px;
			margin: 0px 8px 0px 0px;
			cursor: pointer;
			display: flex;
			align-items: center;
			color: $textTitleColor;
			.material-icons {
				font-size: 28px;
			} 
		}
		.auditButton {
			position: relative;
			margin-left: auto;
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			&+button {
				margin-left: 8px;
			}
		}
	}
	.inputsBlock {
		display: flex;
		flex-direction: column;
	}
	.tabsView {
		margin-bottom: 20px;
	}
	.inputSection {
		.sectionTitle {
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 100%;
			font-size: 22px;
			line-height: 28px;
			color: $textSubtleColor;
			font-weight: 600;
			margin: 32px 0px;
			white-space: nowrap;
			.inputElem {
				margin-left: 10px;
			}
			&::before,
			&::after {
				content: '';
				width: 100%;
				border-bottom: 1px solid $textSubtleColor;
				margin: 0px 12px
			}
		}
	}
	.objectEditInput {
		padding: 8px 0px;
	}
	.buttonContainer {
		padding: 15px 0px 5px;
		button+button {
			margin-left: 15px;
		}
		&:not(.left) {
			&::before {
				content: '';
				display: inline-block;
				width: 20%;
				max-width: 170px;
			}
		}
	}
}

@media all and (max-width: 768px) {
	.objectEditView {
		.buttonContainer {
			&::before {
				display: none;
			}
		}
	}
}
