@import '../../_variables.scss';

.linksList {
  padding: 10px 0px 5px;
  .linkItem {
    display: inline-block;
    padding: 1px 10px;
    border: 1px solid $highlightColor;
    border-radius: 4px;
    margin: 0px 5px 6px 0px;
    cursor: pointer;
    &:hover {
      .linkTitle {
        text-decoration: underline;
      }
    }
    .linkTitle {
      font-size: 14px;
      font-weight: 400;
      max-width: 200px;
      color: $textColor;
      padding: 5px 0px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: $primaryColor;
    }
  }
}
