@import '../../_variables.scss';

.repeatReviseView {
  .inputsContainer  {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px 0px 240px;
    .textInput,
    .selectInput,
    .dateInput,
    .fileInput {
      width: 100%;
      padding: 0px;
      margin: 0px 0px 12px;
    }
    .dateRange {
      display: flex;
      flex-direction: row;
      align-items: center;
      label {
        padding: 0px 10px 12px;
        flex-shrink: 0;
        &:first-child {
          padding-left: 0px;
        }
      }
    }
  }
  .buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 15px 0px 0px;
  }
}

@media all and (max-width: 768px) {
  .repeatReviseView {
  }
}