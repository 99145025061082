@import '../../_variables.scss';

.sidebarSection {
  &.open {
    &>.sectionHeader {
      &::after {
        transform: rotate(180deg);
      }
    }
    &>.sectionItems {
      max-height: 1500px;
    }
  }
  &.level-0 {
    &::before {
      content: '';
      display: block;
      margin: 8px 0px 16px;
      border-top: 1px solid #cccccc;
    }
  }
  .sectionHeader {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    padding: 8px 10px;
    margin: 8px 0px;
    color: #333333;
    cursor: pointer;
    position: relative;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
    &::after {
      position: absolute;
      top: 3px;
      right: 8px;
      font-family: 'Material Icons';
      content: 'expand_more';
      font-size: 20px;
      color: #666666;
      line-height: 30px;
      transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1) 150ms;
      transform: rotate(0deg);
    }
  }
  .sectionItems {
    padding-left: 16px;
    max-height: 0px;
    transition: all 450ms ease-in-out;
    overflow: hidden;
    &>.menuItem {
      &:last-child {
        margin-bottom: 8px;
      }
    }
  }
}

.menuItem {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  padding: 8px 10px;
  color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
  background-color: transparent;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  &.selected {
    color: $primaryColor;
  }
}
