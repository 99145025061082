@import '../_variables.scss';

.loginView {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;
  padding: 70px 0px;
  .loginForm {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid $highlightColor;
    border-radius: 4px;
    width: 100%;
    max-width: 330px;
    padding: 16px;
    .logo {
      width: 100%;
      height: 36px;
      background-image: url(/logo.png);
      background-size: 36px;
      background-repeat: no-repeat;
      background-position: 0px center;
      font-weight: 500;
      font-size: 14px;
      line-height: 36px;
      letter-spacing: 0.25px;
      color: $secondaryColor;
      padding-left: 44px;
      margin-bottom: 20px;
    }
    .loginTitle {
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      color: $textTitleColor;
      letter-spacing: 0.15px;
    }
    .loginSubtitle {
      font-size: 14px;
      line-height: 20px;
      color: $textColor;
      letter-spacing: 0.25px;
    }
    label {
      display: block;
      color: $textColor;
      margin: 16px 0px 2px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
    }
    button {
      margin-top: 20px;
    }
    .bottomRow {
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      letter-spacing: 0.25px;
      margin-top: 20px;
      &+.bottomRow {
        margin-top: 16px;
      }
      span {
        margin: 0px 0.25em;
        color: $primaryColor;
        cursor: pointer;
      }
    }
  }
}
