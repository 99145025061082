@import '../_variables.scss';

.searchView {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  .searchInputs {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
    padding: 16px;
    border-bottom: 1px solid $highlightColor;
  }
  .searchField {
    margin-top: 10px;
    margin-bottom: 4px;
    width: 100%;
    &>label {
      display: block;
      margin: 0px 0px 4px;
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      color: #666666;
    }
    &>input[type="text"] {
      display: inline-block;
      height: 36px;
      width: 100%;
    }
  }
  .buttonsBlock {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .searchButton {
    margin: 16px;
    text-align: center;
    &+.searchButton {
      margin-left: 15px;
    }
  }
  input[type="checkbox"] {
    display: block;
  }
  .Select {
    width: 100%;
  }
  input[type="button"] {
    width: auto;
    height: 35px;
  }
}

@media all and (max-width: 768px) {
  .searchView {
    .searchField {
      width: 100%;
      &:not(:nth-child(3n+1)) {
        margin-left: 0px;
      }
    }
    .searchButton {
    }
  }
}
