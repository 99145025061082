@import '../_variables.scss';

.adminToolsView {
  padding: 16px 20px 32px;
  .toolsTitle {
    margin: 16px 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    &>label {
      font-size: 22px;
      line-height: 28px;
      color: $textColor;
      font-weight: 600;
    }
  }
  .toolSection {
    width: 100%;
    max-width: 550px;
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    border-top: 1px solid #e1e1e1;
    &.groupSelect {
      padding-top: 0px;
      border-top: none;
    }
    &+.toolSection {
      margin-top: 24px;
    }
    .toolName {
      font-size: 20px;
      line-height: 26px;
      color: $textColor;
      margin-bottom: 16px;
    }
    .textInput,
    .selectInput,
    .dateInput,
    .fileInput {
      width: 100%;
      padding: 0px;
      margin: 0px 0px 12px;
    }
    .dateRange {
      display: flex;
      flex-direction: row;
      align-items: center;
      label {
        padding: 0px 10px 12px;
        flex-shrink: 0;
        &:first-child {
          padding-left: 0px;
        }
      }
    }
    button {
      margin-top: 4px;
      &+button {
        margin-top: 10px;
      }
    }
  }
}
