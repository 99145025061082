@import '../../_variables.scss';

.selectInput {
  outline: none;
  &.small {
    .reactSelect {
      .reactSelect__value-container {
        padding: 2px 0px 2px 8px;
      }
    }
  }
  .reactSelect {
    .reactSelect__control {
      border-radius: 4px;
      border: solid 1px $highlightColor;
      min-height: 26px;
    }
    .reactSelect__indicators {
      .reactSelect__indicator-separator {
        display: none;
      }
      .reactSelect__dropdown-indicator {
        padding: 2px 8px;
        //color: #000000;
      }
    }
    .reactSelect__control--is-focused {
      border-color: $primaryColor;
      box-shadow: none;
      .reactSelect__indicators {
        .reactSelect__dropdown-indicator {
          color: $primaryColor;
        }
      }
    }
    .reactSelect__value-container {
      padding: 13px 0px 13px 10px;
      min-height: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      &>div {
        margin: 0px;
        height: 20px;
        padding: 0px;
      }
      .reactSelect__placeholder {
        font-size: 14px;
        line-height: 20px;
      }
      .reactSelect__placeholder+div {
        margin: 0px;
        height: 20px;
      }
      .reactSelect__input {
        input {
          font-size: 14px;
          line-height: 20px;
          height: 20px;
        }
      }
      .reactSelect__single-value {
        font-size: 14px;
        line-height: 20px;
        display: flex;
        flex-direction: row;
        align-content: center;
        .material-icons {
          font-size: 18px;
          margin-right: 6px;
          &.danger {
            color: $errorColor;
          }
          &.success {
            color: $successColor;
          }
        }
      }
      .reactSelect__single-value+div {
        margin: 0px;
        height: 20px;
      }
    }
    .reactSelect__value-container.reactSelect__value-container--is-multi {
      min-height: 56px;
      padding: 9px 0px 9px 10px;
      .reactSelect__multi-value {
        background-color: #B4EDFF;
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 4px 6px 4px 0px;
        padding: 2px 4px;
        height: 30px;
        overflow: hidden;
      }
      .reactSelect__multi-value__label {
        font-size: 14px;
        line-height: 20px;
        color: $textTitleColor;
      }
      .reactSelect__multi-value__remove {
        height: 20px;
        margin-left: 2px;
        cursor: pointer;
      }
    }
    .reactSelect__menuList {
    }
    .reactSelect__menu {
      border-radius: 4px;
      box-shadow: none;
      border: 1px solid $highlightColor;
    }
    .reactSelect__menuPortal {
    }
    .reactSelect__option {
      clear: both;
      color: #000000;
      margin: 0px;
      width: 100%;
      display: block;
      padding: 6px 16px;
      font-size: 14px;
      line-height: 20px;
      transition: all 150ms linear;
      font-weight: 400;
      line-height: 2;
      white-space: nowrap;
      border-radius: 0px;
      &:hover {
        color: #000000;
        background-color: $highlightColor;
      }
    }
    .reactSelect__option--is-focused {
      color: #000000;
      background: rgba(0, 0, 0, 0.05);
    }
    .reactSelect__option--is-selected {
      color: #000000;
      background: rgba(0, 0, 0, 0.05);
    }
  }
}
